<template>
	<div class="page_customers">
		<section class="header-bg" style="">
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong
						style="font-size: 28px;font-family: 'Bebas Neue', cursive;">Customers</strong>
				</p>
			</div>
		</section>
		
		<div class="customer_box">
			<CustomerList :isShowTitle="false" :isShowStatistics="false"></CustomerList>
		</div>

		<section class="container-1200" style="margin-top: 40px;">
			<!-- <div style="height: 20px;"> -->
				<div style="font-size: 24px">Comments</div>
				
			<!-- </div> -->
		</section>
		
		<div class="customer_comment">
			<EvaluateIssue :isShowEvaluate="true" :isShowEvaluateTitle="false" :isShowFaq="false"></EvaluateIssue>
		</div>
	</div>

</template>
<script>
	import CustomerList from "@/components/customerList/index.vue"
	import EvaluateIssue from "@/components/evaluateIssue/index.vue"

	export default {
		name: "customers",
		components:{
			CustomerList,
			EvaluateIssue
		},
		data() {
			return {
				commemt_about: [{
						img: require('@/assets/images/team1.png'), //头像
						name: 'Hossein Moayedi', //名字
						profile: 'Duy Tân University, Engineering Computer Science Chemistry Environmental Sciences & Ecology Materials Science.', //简介
					},
					{
						img: require('@/assets/images/team2.png'), //头像
						name: 'AL DIWANIYAH, IRAQ', //名字
						profile: 'University of Al-QadisiyahSch Educ,Science & Technology - Other Topics.', //简介
					}, {
						img: require('@/assets/images/team3.png'), //头像
						name: 'Dr. Abdulrahman S Bazaid', //名字
						profile: 'Department of Medical Laboratory Science, College of Applied Medical Sciences, University of Ha’il, Ha’il.', //简介
					},
				]
			};
		},
		watch: {},
		created() {

		},
		mounted() {},
		methods: {

		},
	}
</script>

<style lang="scss" scoped>
	.page_customers{
		.customer_box{
			margin-top: 50px;
			display: flex;
			justify-content: center;
		}
	
		.customer_comment{
			margin-top: 30px;
		}
	}

	.customerImg1 {
		margin-left: 15px;
	}

	.customerImg img {
		width: 200px;
		height: 70px;
		margin-bottom: 15px;
	}

	.comment {
		border: 1px solid #eee;
		display: flex;
		align-items: center;
	}

	.avatar {
		flex: 2;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.avatarImage{
		padding: 25px 0px;
		width: 120px;
		height: 120px;
		border-radius: 50%;
	}

	.comment_content {
		flex: 8;
	}

	.comment_content p {
		padding: 0px 20px;
	}

	/* 轮播形式 */
	.el-carousel__item h3 {
		color: #475669;
		font-size: 18px;
		opacity: 0.75;
		line-height: 300px;
		margin: 0;
	}
	
	.el-carousel__item{
		height:unset;
	}
</style>