import customer1 from "@/assets/images/customer2.png"
import customer2 from "@/assets/images/customer1.png"
import customer3 from "@/assets/images/customer1_3.png"
import customer4 from "@/assets/images/customer1_4.png"

export const data = [
    {
        image: customer1,
        link: "https://www.zibelinepub.com/"
    },
    {
        image: customer2,
        link: "https://www.volksonpress.com/"
    },
    {
        image: customer3,
        link: "https://www.discovmed.com"
    },
    {
        image: customer4,
        link: "https://kneopen.com/"
    }
]