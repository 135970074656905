<template>
    <div class="customer_list">
        <div class="customer_titie" v-if="isShowTitle">
            OUR CUSTOMERS
        </div>
        <div class="customer_content">
            <div class="customer_statistics" v-if="isShowStatistics">
                <div class="statistics_box">
                    * There have <span class="storng_text">{{ statistics.installedCount }}</span> Publisher are already
                    using
                    Contrimetric·Plugin...
                </div>
                <div class="statistics_box">
                    * There have <span class="storng_text">{{ statistics.noInstallCount }}</span> Journals have
                    completed
                    plugin code
                    production...
                </div>
            </div>
            <div class="customer_image_list" v-if="isShowImage">
                <div id="list_item" :class="['list_item', `item-${index}`]" v-for="(item, index) in data" :key="index">
                    <img :src="item.image" alt="" @click="goToBlank(item.link)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getUseJournalCount } from '@/api/widget';
import { data } from './data';

export default {
    props: {
        isShowTitle: {
            type: Boolean,
            default: true
        },
        isShowStatistics: {
            type: Boolean,
            default: true
        },
        isShowImage: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            data,
            statistics: {
                noInstallCount: 0,
                installedCount: 0
            }
        }
    },
    methods: {
        goToBlank(link) {
            window.open(link, '_blank')
        },
        async getUseJournalCountFun() {
            await getUseJournalCount().then(res => {
                console.log({ ...res.data });
                this.statistics = { ...res.data }
            })
        }
    },
    async mounted() {
        if (this.isShowStatistics) await this.getUseJournalCountFun()
    },
}
</script>

<style lang="scss">
@import "../../assets/css/minix.scss";

.customer_list {
    @include responsive-width(1200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .customer_titie {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .customer_content {
        width: 100%;

        .customer_statistics {
            position: relative;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-around;
            font-size: 16px;
            width: 100%;
            padding: 20px 100px;
            background-color: #f4e5e5;
            box-sizing: border-box;
            overflow: hidden;

            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                width: 35px;
                height: 100%;
                background-color: white;
            }

            &::before {
                left: 0;
                transform: skew(336deg);
                transform-origin: top right;
            }

            &::after {
                right: -28px;
                transform: skew(340deg);
                transform-origin: top left;
            }

            .statistics_box {
                .storng_text {
                    color: #8e0000;
                    font-size: 30px;
                    font-family: fantasy;
                }
            }
        }

        .customer_image_list {
            display: flex;
            flex-wrap: wrap;

            .list_item {
                display: flex;
                align-items: center;
                margin: 0 20px;
                transition: all .3s;

                &:hover {
                    cursor: pointer;
                    filter: opacity(0.7);
                }

                &.item-0 {
                    img {
                        width: 200px;
                    }
                }

                &.item-1 {
                    img {
                        width: 200px;
                    }
                }

                &.item-2 {
                    img {
                        width: 404px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .customer_list {
        .customer_content {
            .customer_statistics {
                position: static;
                flex-direction: column;
                padding: 20px;

                &::before,
                &::after {
                    content: "";
                    display: none;
                }

                .statistics_box {
                    text-align: justify;

                    &:nth-child(1) {
                        margin-bottom: 20px;
                    }
                }
            }

            .customer_image_list {
                #list_item {
                    margin: 0 10px;
                    flex: 0 0 44%;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>